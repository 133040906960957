import React, { useState } from "react"
import { useIntl } from "gatsby-plugin-intl"
import classNames from "classnames"
import { Button, Modal } from "antd"
import moment from "moment"
import QRCode from "qrcode.react"
import ContractLayout from "../../components/ContractLayout"
import SEO from "../../components/SEO"
import style from "./index.module.scss"
import statusSuccessImg from "../../images/icon/status-success.svg"
import copyImg from "../../images/icon/copy.png"
import SecurityLevel from "../../components/SecurityLevel"
import ContentLayout from "../../components/ContentLayout"
// import { sendTransaction } from "../../utils/conflux"
import contractLoadingImg from "../../images/icon/contract-loading.png"
import { URL_SCAN } from "../../utils/const"

const IndexPage = () => {
  const intl = useIntl()
  let countDown = 5
  const preContractLoadingText = "合同信息上链中"
  const [contractLoading, setContractLoading] = useState(false)
  const [showContractSuccess, setShowContractSuccess] = useState(false)
  const [contractLoadingText, setContractLoadingText] = useState(
    `${preContractLoadingText}(${countDown}S)......`
  )
  const [tx] = useState(
    "0xf09d150c8abf787d344998566a9371a8b9fc79baec8123635ba2449343193652"
  )
  const modalBodyStyle = {
    textAlign: "center",
    paddingBottom: "32px",
  }
  function signContract() {
    setContractLoading(true)
    // sendTransaction().then(data => {
    //   setTx(data.hash)
    //   console.log(data)
    // })
    const interval = setInterval(() => {
      if (countDown === 0) {
        setContractLoading(false)
        clearInterval(interval)
        setShowContractSuccess(true)
      }
      setContractLoadingText(`${preContractLoadingText}(${countDown}S)......`)
      countDown--
    }, 1000)
  }
  function handleLoadingCancel(e) {
    e.preventDefault()
    setContractLoading(false)
  }

  function handleSuccessCancel(e) {
    e.preventDefault()
    setShowContractSuccess(false)
  }

  return (
    <ContractLayout>
      <SEO
        title={intl.formatMessage({
          id: "seo.home.title",
        })}
        lang={intl.formatMessage({
          id: "seo.home.lang",
        })}
        description={intl.formatMessage({
          id: "seo.home.description",
        })}
        keywords={intl.formatMessage({
          id: "seo.home.keywords",
        })}
      />
      <ContentLayout>
        <div className={style.pageContainer}>
          <div className={style.numberContainer}>
            合同编号 0211-7896452356****
          </div>
          <div className={style.tableContainer}>
            <div className={classNames(style.lineContainer)}>
              <div className={classNames(style.leftContainer, style.first)}>
                交易哈希
              </div>
              <div className={classNames(style.rightContainer, style.first)}>
                <span>
                  0x446276d0c2aac7b8f3a0c1fb8cce300c9c54f508b9ffac5a0ee8a5525f377bdc
                </span>
                <img src={copyImg} className={style.copyImg} />
              </div>
            </div>
            <div className={style.lineContainer}>
              <div className={style.leftContainer}>时间戳</div>
              <div className={style.rightContainer}>
                <span>
                  0 hr 0 min 10 secs ago (
                  {moment(Date.now()).format("YYYY-MM-DD HH:mm:ss Z")})
                </span>
              </div>
            </div>
            <div className={style.lineContainer}>
              <div className={style.leftContainer}>状态</div>
              <div className={style.rightContainer}>
                <img
                  src={statusSuccessImg}
                  className={style.statusSuccessImg}
                />
                <span className={style.statusSuccessText}>成功</span>
              </div>
            </div>
            <div className={style.lineContainer}>
              <div className={style.leftContainer}>安全等级</div>
              <div className={style.rightContainer}>
                <SecurityLevel riskLevel="lv0" />
              </div>
            </div>
            <div className={style.lineContainer}>
              <div className={style.leftContainer}>发送方</div>
              <div className={style.rightContainer}>
                <span>0xb0efopsjnci0w03840js02ks-2ks0388ks8390xn830209jd9</span>
                <img src={copyImg} className={style.copyImg} />
              </div>
            </div>
            <div className={style.lineContainer}>
              <div className={classNames(style.leftContainer, style.last)}>
                接收方
              </div>
              <div className={classNames(style.rightContainer, style.last)}>
                <span>0xb0efopsjnci0w03840js02ks-2ks0388ks8390xn830209jd9</span>
                <img src={copyImg} className={style.copyImg} />
              </div>
            </div>
          </div>
          <div className={style.commonTableContainer}>
            <div className={classNames(style.lineContainer)}>
              <div className={classNames(style.leftContainer, style.first)}>
                项目名称
              </div>
              <div className={classNames(style.rightContainer, style.first)}>
                <span>上海徐汇艺术西岸项目</span>
              </div>
            </div>
            <div className={style.lineContainer}>
              <div className={style.leftContainer}>项目建设地点</div>
              <div className={style.rightContainer}>
                <span>上海徐汇西岸艺术中心</span>
              </div>
            </div>
            <div className={style.lineContainer}>
              <div className={style.leftContainer}>合同编号</div>
              <div className={style.rightContainer}>
                <span>0211-7896452356****</span>
              </div>
            </div>
            <div className={style.lineContainer}>
              <div className={style.leftContainer}>委托方</div>
              <div className={style.rightContainer}>
                <span>上海****开发有限公司</span>
              </div>
            </div>
            <div className={style.lineContainer}>
              <div className={style.leftContainer}>承接方</div>
              <div className={style.rightContainer}>
                <span>上海****集团有限公司</span>
              </div>
            </div>
          </div>
          <div className={style.btnContainer}>
            <Button
              type="primary"
              disabled
              className={classNames(style.btn, style.see)}
            >
              查看合同原件
            </Button>
            <Button
              type="primary"
              className={classNames(style.btn, style.primary, style.margin_big)}
              onClick={signContract}
            >
              签订区块链合同
            </Button>
          </div>
        </div>
      </ContentLayout>
      <Modal
        visible={contractLoading}
        bodyStyle={modalBodyStyle}
        footer={null}
        onCancel={handleLoadingCancel}
        centered
      >
        <img src={contractLoadingImg} className={style.contractLoadingImg} />
        <div>
          <span className={style.contractTip}>{contractLoadingText}</span>
        </div>
      </Modal>
      <Modal
        visible={showContractSuccess}
        bodyStyle={modalBodyStyle}
        footer={null}
        onCancel={handleSuccessCancel}
        centered
      >
        <QRCode
          value={URL_SCAN + `/transactionsdetail/${tx}`}
          className={style.qcContainer}
        />
        <div>
          <span className={style.contractSuccessTip}>合同信息已成功上链</span>
        </div>
        <div className={style.hashContainer}>
          {/* 哈希:<span className={style.hash}>{tx}</span>
          <img src={copyImg} className={style.copyImgSmall} /> */}
          <span>合同正式开始执行</span>
        </div>
        <Button
          type="primary"
          className={classNames(
            style.btn,
            style.primary,
            style.link,
            style.margin_top_link
          )}
          href={URL_SCAN + `/transactionsdetail/${tx}`}
          target="_black"
        >
          查看链接
        </Button>
      </Modal>
    </ContractLayout>
  )
}

export default IndexPage
